import { PermissionCheckerService, FeatureCheckerService, LocalizationService, MessageService, AbpMultiTenancyService, NotifyService, SettingService } from 'abp-ng2-module';
import { ElementRef, Injectable, Injector, QueryList, ViewChildren } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { DynamicPageStateDto, DynamicPageStateServiceProxy, DynamicPermissionServiceProxy, GridFeatureUsageTrackerDto, GridFeatureUsageTrackerServiceProxy, GuidKeyValuePairDto, UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { process, aggregateBy, distinct, GroupDescriptor, State, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';
import { DisbursementDetailsServiceProxy, GridStateServiceProxy, SetGridStateInput, DisbursementDto, GridStateDto, PaymentNoteDto, PaymentForUpdate, ClientAndLocationServiceProxy, ClientAndLocationDto, SetClientAndLocationInput } from '@shared/service-proxies/service-proxies';
import { GridSettings } from '@app/shared/common/dynamic-components/dynamic-grid/grid-settings.interface';
import { ColumnSettings } from '@app/shared/common/dynamic-components/dynamic-grid/column-settings.interface';
import { merge as _merge, cloneDeep as _cloneDeep, omit, capitalize, isNil as _isNil, isEmpty as _isEmpty } from 'lodash-es';
import { DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { LoadingStateService } from '@shared/utils/loading-state.service';
import { DateTime } from 'luxon';
import { ItemsStateService } from '@shared/utils/items-state.service';
import { catchError } from 'rxjs/operators';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { ClientAndErpService } from '@shared/utils/client-and-erp.service';
import { ControlTypeNames, GridActionType } from '@shared/AppEnums';

@Injectable()
export abstract class AppComponentBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    private ngxSpinnerTextService: NgxSpinnerTextService;

    intl: IntlService;
    loadingStateService: LoadingStateService;
    clientAndErpService: ClientAndErpService;
    itemsStateService: ItemsStateService;
    dynamicPermission: DynamicPermissionServiceProxy;

    // PDF Viewer related
    disbursementDetailsServiceProxy: DisbursementDetailsServiceProxy;

    runningDocFetch: any;
    clearedCheckImages: any;
    isPdfViewerVisible: boolean = false;
    isPdfViewerScrollable: boolean;
    pdfSrc: string;
    pdfName: string;

    @ViewChildren('slsPdfviewer') pdfViewer: QueryList<ElementRef>;

    // Save Grid State
    gridStateServiceProxy: GridStateServiceProxy;
    gridFeatureUsageTrackerServiceProxy: GridFeatureUsageTrackerServiceProxy;
    isGrouped: boolean;

    // Client And Location
    clientAndLocationServiceProxy: ClientAndLocationServiceProxy;
    clientsFilter: GuidKeyValuePairDto[] = [];
    locationsFilter: GuidKeyValuePairDto[] = [];

    dynamicPageStateServiceProxy: DynamicPageStateServiceProxy;

    upperGridDisplayedItemCount: number;
    lowerGridDisplayedItemCount: number;

    distinctAggregates: any[] = [
        { field: 'paymentAmount', aggregate: 'sum' }
    ];

    gridHasNoRecords: boolean;

    groups: { field: string; editor: string }[] = [];
    gridItems: any[] = [];
    collapsedGroups: any[] = [];
    invoiceAmountDictionary: { [id: string]: number } = {};
    paymentAmountDictionary: { [id: string]: number } = {};
    amountRemainingDictionary: { [id: string]: number } = {};
    discountAmountDictionary: { [id: string]: number } = {};
    checkAmountDictionary: { [id: string]: number } = {};
    itemCount: number = 0;
    firstItem: any;
    sumItemsTotalTime = 0;
    collapsedGroupList: any[] = [];
    filteredItemCount: number = 0;
    hasGridFilter: boolean = false;
    detailedTimingBreakdown: any[] = [];

    readonly controlTypeNames: typeof ControlTypeNames = ControlTypeNames;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.intl = injector.get(IntlService);
        this.disbursementDetailsServiceProxy = injector.get(DisbursementDetailsServiceProxy);
        this.gridStateServiceProxy = injector.get(GridStateServiceProxy);
        this.gridFeatureUsageTrackerServiceProxy = injector.get(GridFeatureUsageTrackerServiceProxy);
        this.clientAndLocationServiceProxy = injector.get(ClientAndLocationServiceProxy);
        this.dynamicPageStateServiceProxy = injector.get(DynamicPageStateServiceProxy);
        this.loadingStateService = injector.get(LoadingStateService);
        this.clientAndErpService = injector.get(ClientAndErpService);
        this.itemsStateService = injector.get(ItemsStateService);
        this.dynamicPermission = injector.get(DynamicPermissionServiceProxy);
    }

    convertPascalCaseToKebabCase(name: string): string {
        return name.replace(/(?<!^)([A-Z][a-z]|(?<=[a-z])[A-Z])/g, function (x, y) { return "-" + y.toLowerCase() }).replace(/^_/, "").toLowerCase();
    }

    convertKebabCaseToPascalCase(string) {
        if (string !== undefined) {
            // splitting words by dash
            const words = string.split('-');

            // Capitalize the first letter of each word
            const pascalWords = words.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            });

            // Insert hyphen between words if it was present in the original string
            for (let i = 2; i < pascalWords.length; i++) {
                if (words[i - 1] === '' && words[i - 2] === '') {
                    pascalWords[i - 1] = '-' + pascalWords[i - 1];
                }
            }

            // Join the words to form the PascalCase string
            const pascalString = pascalWords.join('');

            return pascalString;
        }

        return undefined;
    }

    convertKebabCaseToPascalCaseCustom(text: string, join: string) {
        // splitting words by dash
        const words = text.split('-');
        // use capitalize function to capitalize every word
        const capitalized = words.map(word => capitalize(word));
        // glue up words with .join()
        return capitalized.join(join);
    }

    flattenDeep(array) {
        return array.reduce((acc, val) =>
            Array.isArray(val) ?
                acc.concat(this.flattenDeep(val)) :
                acc.concat(val),
            []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    isDynamicPermissionGranted(permissionName: string): boolean {
        return (this.appSession.dynamicPermissionNames.indexOf(permissionName) > -1);
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    isPostSignupIncomplete(): boolean {
        return this.appSession.completedBankMaintenance === false ||
            this.appSession.completedClientMaintenance === false ||
            this.appSession.completedEntityMaintenance === false ||
            this.appSession.completedUserMaintenance === false;
    }

    isPostSignupError(): boolean {
        return this.appSession.completedBankMaintenance === null ||
            this.appSession.completedClientMaintenance === null ||
            this.appSession.completedEntityMaintenance === null ||
            this.appSession.completedUserMaintenance === null;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'container-fluid';
        }

        return 'container';
    }

    getSpinnerText(): string {
        return this.ngxSpinnerTextService.getText();
    }

    showMainSpinner(text?: string): void {
        if (text === null || text === undefined) {
            this.ngxSpinnerTextService.setText("LOADING...");
        }
        else {
            this.ngxSpinnerTextService.setText(this.l(text));
        }
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    formatNumber(amount: number, currency?: string): string {
        if (isNaN(amount)) {
            return "";
        }

        if (amount < 0) {
            let result = this.intl.formatNumber(Math.abs(amount), { style: 'currency', currency: currency, currencyDisplay: 'symbol' });
            let parsedCurrency = result.slice(0, 1);
            let parsedAmount = result.slice(1, result.length);

            return parsedCurrency + "(" + parsedAmount + ")";
        }
        else {
            return this.intl.formatNumber(amount, { style: 'currency', currency: currency, currencyDisplay: 'symbol' });
        }
    }

    formatDate(date: any, format?: string): string {
        if (date !== null && date?.isLuxonDateTime) {
            date = date.toJSDate();
        }

        if (format === undefined) {
            format = 'MM/dd/yyyy';
        }

        return this.intl.formatDate(date, format);
    }

    formatItemDateFromJSDateToLuxonDate(item) {
        for (var key in item) {
            if (key.toLowerCase().includes("date") === true) {
                if (item[key]?.isLuxonDateTime === undefined) {
                    item[key] = item[key] ? DateTime.fromJSDate(item[key]) : <any>undefined;
                }
            }
        }

        return item;
    }

    applyIconToGroup(groupField: string, groupValue: string, editor: string): string {
        if ((groupField === "pay" || groupField === "approve" || groupField === "syncedToERP" || groupField === "exportToSage" || groupField === "isActive" || groupField === "isSelected") || editor === "boolean")
            return groupValue ? 'k-icon k-i-checkbox-checked' : 'k-icon k-i-checkbox';
        return '';
    }

    formatDateNumericOrString(groupField: string, groupValue: any, columnSettings: any, comboBoxData: any = {}): string {
        let value = "";

        const field = columnSettings.find((x: { field: string; }) => x.field === groupField);

        if (groupField === "checkNumber" && groupValue === "") {
            value = this.l('Unassigned');
        } else if (groupField === "pay") {
            value = groupValue === true || groupValue === this.l("Selected") ? this.l("Selected") : this.l("Unselected");
        } else if (groupField === "approve") {
            value = groupValue === true || groupValue === this.l("Approved") ? this.l("Approved") : this.l("Unapproved");
        } else if (groupField === "syncedToERP") {
            value = groupValue === true || groupValue === this.l("Synced") ? this.l("SyncedPayments") : this.l("UnsyncedPayments");
        } else if (groupField === "exportToSage") {
            value = groupValue === true || groupValue === this.l("PaymentToExport") ? this.l("PaymentsToExport") : this.l("UnexportedPayments");
        } else if (groupField === "isActive") {
            value = groupValue === true || groupValue === this.l("Active") ? this.l("Active") : this.l("Inactive");
        } else {
            if (field.filter !== undefined) {
                if (field.filter === "date") {
                    value = this.formatDate(groupValue);
                } else if (field.filter === "numeric") {
                    value = this.formatNumber(groupValue);
                }
                else if (comboBoxData[groupField] !== undefined) {
                    var parsedResult = comboBoxData[groupField].find(x => x.id === groupValue || String(x.id) === String(groupValue));
                    if (parsedResult !== undefined) {
                        value = parsedResult.name;
                    }
                    else {
                        value = groupValue;
                    }
                }
                else if (groupValue === undefined) {
                    value = this.l("Undefined");
                }
                else if (groupValue === null) {
                    value = this.l("Null");
                }
                else {
                    value = groupValue;
                }
            }
            else if (groupValue === undefined) {
                value = this.l("Undefined");
            }
            else if (groupValue === null) {
                value = this.l("Null");
            }
            else {
                value = groupValue;
            }
        }

        return value;
    }

    customAggregates: any[] = [
        { field: 'invoiceAmount', aggregate: 'sum' },
        { field: 'discount', aggregate: 'sum' },
        { field: 'paymentAmount', aggregate: 'sum' },
        { field: 'amountRemaining', aggregate: 'sum' }
    ];

    padValue(value): string {
        return (value < 10) ? "0" + value : value;
    }

    // Function to simulate a task and use the timer
    addTime(label, duration) {
        console.time(label);

        setTimeout(() => {
            console.timeEnd(label);
        }, duration);
    }

    sumItems(group, field: string, action, aggregates, distinctAggregates = undefined, items = undefined, timeSumItems = false): string {
        let label: string;
        let startTime: number;

        if (timeSumItems) {
            console.log("sumItems-----------------------------------------------------");

            // Start the timer with a unique label for this invocation
            label = `Timer-${field}-${Date.now()}`;
            startTime = Date.now();
            console.time(label);

            ////////////////////////////////////////////////////////////////////////
        }

        let filtered = group.items;
        let aggregated = {};
        let hasDistinctAggregates: boolean;
        let key = "";

        if (items.length !== this.itemCount || this.firstItem === undefined || this.firstItem.id !== items[0].id) {
            this.itemCount = items.length;
            this.clearDictionaries();
            this.firstItem = items.length > 0 ? items[0] : undefined;
        }

        // Totals for 5th group and beyond won't be calculated.
        let currentGroupIndex = this.groups.findIndex(g => g.field === group.field) + 1;
        if (currentGroupIndex > 4) {
            return this.formatNumber(Number.NaN);
        }

        //Expectation: If a group is collapsed, the totals to be displayed will be the total of all payments (not only the payments in the first page) in the group.
        const hasCollapsedGroup = this.collapsedGroups.findIndex(g => g.field === group.field && g.value === group.value) > -1;

        // Added extra queries for Payment Amount and Invoice Amount because some payments go beyond one page which are also required to be processed
        if (hasCollapsedGroup || (action === "History" && ((field === "checkAmount" && group.field === "checkNumber" && group.value !== "") ||
            (field === "invoiceAmount" && group.field === "billNumber")))) {
            this.getGridFilteredItemsForSumItems(filtered);

            let gridItem = this.gridItems[0];

            if (this.itemsStateService.clonedItems === null || this.itemsStateService.clonedItems === undefined) {
                return "";
            }

            let cleanedFilter: any[] = this.itemsStateService.clonedItems;

            // Build Keys for Invoice Amount Dictionary
            for (var i = 0; i < this.groups.findIndex(g => g.field === group.field) + 1; i++) {
                if (i + 1 < this.groups.findIndex(g => g.field === group.field) + 1) {
                    let value = gridItem[this.groups[i].field];
                    if (value !== undefined) {
                        value = value;
                    }
                    if (value instanceof Date) {
                        let hour = value.getHours();
                        if (hour > 12) {
                            hour = hour - 12;
                        }
                        else if (hour === 0) {
                            hour = 12
                        }
                        let ampm = (value.getHours() < 12) ? " am" : " pm";
                        let dateValue = [(value.getMonth() + 1), value.getDate(),
                        value.getFullYear()].join('/') + ' ' + [
                            this.padValue(hour),
                            this.padValue(value.getMinutes()),
                            this.padValue(value.getSeconds())].join(':') + ampm;

                        key += this.groups[i].field + ":" + dateValue + ",";
                    }
                    else {
                        key += this.groups[i].field + ":" + gridItem[this.groups[i].field] + ",";
                    }
                }
                else {
                    let value = group.value;
                    if (value instanceof Date) {
                        let hour = value.getHours();
                        if (hour > 12) {
                            hour -= 12;
                        }
                        else if (hour === 0) {
                            hour = 12
                        }
                        let ampm = (value.getHours() < 12) ? " am" : " pm";
                        let dateValue = [(value.getMonth() + 1), value.getDate(),
                        value.getFullYear()].join('/') + ' ' + [this.padValue(hour),
                        this.padValue(value.getMinutes()),
                        this.padValue(value.getSeconds())].join(':') + ampm;

                        key += this.groups[i].field + ":" + dateValue;
                    }
                    else {
                        key += this.groups[i].field + ":" + value;
                    }
                }
            }

            // Retrieve overall invoice amount sum based on their corresponding key and store them in the dictionary
            if (this.invoiceAmountDictionary[key] === undefined) {

                for (var i = 0; i < this.groups.findIndex(g => g.field === group.field) + 1; i++) {
                    let value = gridItem[this.groups[i].field];

                    cleanedFilter = cleanedFilter.filter(f => f[this.groups[i].field] === value);
                }

                let sum = 0;

                if (action === "History") {
                    let unique = [];
                    let distinct = [];

                    for (let i = 0; i < cleanedFilter.length; i++) {
                        if (!unique[cleanedFilter[i].billNumber]) {
                            distinct.push(cleanedFilter[i]);
                            unique[cleanedFilter[i].billNumber] = 1;
                        }
                    }

                    distinct.forEach(d => sum += d.invoiceAmount);
                }
                else {
                    cleanedFilter.forEach(cf => sum += cf.invoiceAmount);
                }

                this.invoiceAmountDictionary[key] = sum;
                sum = 0;

                cleanedFilter.forEach(cf => sum += cf.discount);
                this.discountAmountDictionary[key] = sum;
                sum = 0;

                cleanedFilter.forEach(cf => sum += cf.amountRemaining);
                this.amountRemainingDictionary[key] = sum;
                sum = 0;

                if (action !== "History") {
                    let dataWithoutUnassignedCheckNumber = cleanedFilter.filter(x => x.checkNumber !== "");
                    let dataWithUnassignedCheckNumber = cleanedFilter.filter(x => x.checkNumber === "");

                    let unique = [];
                    let distinct = [];

                    for (let i = 0; i < dataWithoutUnassignedCheckNumber.length; i++) {
                        if (!unique[dataWithoutUnassignedCheckNumber[i].checkNumber]) {
                            distinct.push(dataWithoutUnassignedCheckNumber[i]);
                            unique[dataWithoutUnassignedCheckNumber[i].checkNumber] = 1;
                        }
                        else {
                            let similarBankAccount = distinct.findIndex(d => d.bankAccountId === dataWithoutUnassignedCheckNumber[i].bankAccountId);

                            if (similarBankAccount === -1) {
                                distinct.push(dataWithoutUnassignedCheckNumber[i]);
                            }
                        }
                    }

                    distinct.push(...dataWithUnassignedCheckNumber);
                    distinct.forEach(d => sum += d.paymentAmount);
                }
                else {
                    cleanedFilter.forEach(cf => sum += cf.paymentAmount);
                }

                this.paymentAmountDictionary[key] = sum;
                sum = 0;

                let dataWithoutUnassignedCheckNumber = cleanedFilter.filter(x => x.checkNumber !== "");
                let dataWithUnassignedCheckNumber = cleanedFilter.filter(x => x.checkNumber === "");

                let unique = [];
                let distinct = [];

                for (let i = 0; i < dataWithoutUnassignedCheckNumber.length; i++) {
                    if (!unique[dataWithoutUnassignedCheckNumber[i].checkNumber]) {
                        distinct.push(dataWithoutUnassignedCheckNumber[i]);
                        unique[dataWithoutUnassignedCheckNumber[i].checkNumber] = 1;
                    }
                    else {
                        let similarBankAccount = distinct.findIndex(d =>
                            d.bankAccountId === dataWithoutUnassignedCheckNumber[i].bankAccountId);
                        if (similarBankAccount === -1) {
                            distinct.push(dataWithoutUnassignedCheckNumber[i]);
                        }
                    }
                }

                distinct.push(...dataWithUnassignedCheckNumber);
                distinct.forEach(d => sum += d.checkAmount);
                this.checkAmountDictionary[key] = sum;
            }

            if (timeSumItems) {
                ///////////////////////////////////////////////////////////////////////////////////

                // End the timer and calculate the elapsed time
                console.timeEnd(label);
                const endTime = Date.now();
                const timeElapsed = endTime - startTime;

                // Add the elapsed time to the total accumulated time
                this.sumItemsTotalTime += timeElapsed;
                // console.clear();
                // Log the total accumulated time without the 'ms' suffix
                console.log(`Total accumulated time: ${this.sumItemsTotalTime} ms`);

                ///////////////////////////////////////////////////////////////////////////////////
            }

            if (field === "invoiceAmount") {
                return this.formatNumber(this.invoiceAmountDictionary[key]);
            }
            else if (field === "paymentAmount") {
                return this.formatNumber(this.paymentAmountDictionary[key]);
            }
            else if (field === "discount") {
                return this.formatNumber(this.discountAmountDictionary[key]);
            }
            else if (field === "checkAmount") {
                return this.formatNumber(this.checkAmountDictionary[key]);
            }
            else {
                return this.formatNumber(this.amountRemainingDictionary[key]);
            }
        }
        else {
            if (distinctAggregates) {
                hasDistinctAggregates = distinctAggregates.find((x: { field: string; }) => x.field === field);

                if ((hasDistinctAggregates && action !== "History") || field === "checkAmount") {
                    let distinct = [];
                    const dataWithoutUnassignedCheckNumber = filtered.filter(x => x.checkNumber !== "");
                    const dataWithUnassignedCheckNumber = filtered.filter(x => x.checkNumber === ""); let unique = [];
                    for (let i = 0; i < dataWithoutUnassignedCheckNumber.length; i++) {
                        if (!unique[dataWithoutUnassignedCheckNumber[i].checkNumber]) {
                            distinct.push(dataWithoutUnassignedCheckNumber[i]);
                            unique[dataWithoutUnassignedCheckNumber[i].checkNumber] = 1;
                        }
                        else {
                            let similarBankAccount = distinct.findIndex(d =>
                                d.bankAccountId === dataWithoutUnassignedCheckNumber[i].bankAccountId);
                            if (similarBankAccount === -1) {
                                distinct.push(dataWithoutUnassignedCheckNumber[i]);
                            }
                        }
                    }
                    distinct.push(...dataWithUnassignedCheckNumber);
                    filtered = distinct;
                    if (field !== "checkAmount") {
                        aggregates = distinctAggregates;
                    }
                }
                else if (hasDistinctAggregates && action === "History") {
                    aggregates = distinctAggregates;
                }
            }

            if (filtered[0].aggregates == undefined) {
                // column group 1
                aggregated = aggregateBy(filtered, aggregates);

            } else {

                let filtered2 = this.getItems(filtered);

                if (filtered[0].items[0].aggregates == undefined) {
                    // column group 2
                    aggregated = aggregateBy(filtered2, aggregates);

                } else {

                    let filtered3 = this.getItems(filtered2);

                    if (filtered[0].items[0].items[0].aggregates == undefined) {
                        // column group 3
                        aggregated = aggregateBy(filtered3, aggregates);

                    } else {

                        let filtered4 = this.getItems(filtered3);

                        if (filtered[0].items[0].items[0].items[0].aggregates == undefined) {
                            // column group 4
                            aggregated = aggregateBy(filtered4, aggregates);

                        } else {

                            let filtered5 = this.getItems(filtered4);

                            if (filtered[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                // column group 5
                                aggregated = aggregateBy(filtered5, aggregates);

                            } else {

                                let filtered6 = this.getItems(filtered5);

                                if (filtered[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                    // column group 6
                                    aggregated = aggregateBy(filtered6, aggregates);

                                } else {

                                    let filtered7 = this.getItems(filtered6);

                                    if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                        // column group 7
                                        aggregated = aggregateBy(filtered7, aggregates);

                                    } else {

                                        let filtered8 = this.getItems(filtered7);

                                        if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                            // column group 8
                                            aggregated = aggregateBy(filtered8, aggregates);

                                        } else {

                                            let filtered9 = this.getItems(filtered8);

                                            if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                                // column group 9
                                                aggregated = aggregateBy(filtered9, aggregates);

                                            } else {

                                                let filtered10 = this.getItems(filtered9);

                                                if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                                    // column group 10
                                                    aggregated = aggregateBy(filtered10, aggregates);

                                                } else {

                                                    let filtered11 = this.getItems(filtered10);

                                                    if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                                        // column group 11
                                                        aggregated = aggregateBy(filtered11, aggregates);

                                                    } else {

                                                        let filtered12 = this.getItems(filtered11);

                                                        if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                                            // column group 12
                                                            aggregated = aggregateBy(filtered12, aggregates);

                                                        } else {

                                                            let filtered13 = this.getItems(filtered12);

                                                            if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                                                // column group 13
                                                                aggregated = aggregateBy(filtered13, aggregates);

                                                            } else {

                                                                let filtered14 = this.getItems(filtered13);

                                                                if (filtered[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].items[0].aggregates == undefined) {
                                                                    // column group 14
                                                                    aggregated = aggregateBy(filtered14, aggregates);

                                                                } else {

                                                                    let filtered15 = this.getItems(filtered14);

                                                                    // column group 15
                                                                    aggregated = aggregateBy(filtered15, aggregates);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let value = aggregated[field] === undefined ? 0 : aggregated[field].sum;

            if (hasDistinctAggregates && field === "paymentAmount") {
                if (filtered.length !== group.items.length) {
                    if (group.items[0].aggregates !== undefined) {
                        let summed = 0;
                        group.items.map(x => {
                            summed += x.aggregates.paymentAmount.sum;
                        });
                        value = summed;
                    }
                }
            }

            if (timeSumItems) {
                ///////////////////////////////////////////////////////////////////////////////////

                // End the timer and calculate the elapsed time
                console.timeEnd(label);
                const endTime = Date.now();
                const timeElapsed = endTime - startTime;

                // Add the elapsed time to the total accumulated time
                this.sumItemsTotalTime += timeElapsed;
                // console.clear();
                // Log the total accumulated time without the 'ms' suffix
                console.log(`Total accumulated time: ${this.sumItemsTotalTime} ms`);

                ///////////////////////////////////////////////////////////////////////////////////
            }

            return this.formatNumber(value);
        }
    }

    clonePaymentsForGroupTotalsComputation(payments): void {
        this.itemsStateService.clonedItems = _cloneDeep(payments);
    }

    getItems(filtered) {
        let newFiltered = [];

        filtered.forEach(x => {
            newFiltered.push(...x.items);
        });

        return newFiltered;
    }

    // Save Grid State
    onReorder(e: any, gridSettings: GridSettings): void {
        const reorderedColumn = gridSettings.columnsConfig.splice(e.oldIndex, 1);
        gridSettings.columnsConfig.splice(e.newIndex, 0, ...reorderedColumn);

        this.saveGridState(gridSettings);
    }

    onResize(e: any, gridSettings: GridSettings): void {
        e.forEach(item => {
            gridSettings.columnsConfig.find(col => col.field === item.column.field)._width = item.newWidth;
        });

        this.saveGridState(gridSettings);
    }

    onVisibilityChange(e: any, gridSettings: GridSettings): void {
        e.columns.forEach(column => {
            gridSettings.columnsConfig.find(col => col.field === column.field).hidden = column.hidden;
        });

        this.saveGridState(gridSettings);
    }

    filterChange(gridSettings: GridSettings): void {
        this.saveGridState(gridSettings);
    }

    saveGridState(gridSettings: GridSettings, isDefault: boolean = false): void {
        const gridConfig = {
            columnsConfig: gridSettings.columnsConfig,
            state: gridSettings.state
        };

        let input = new SetGridStateInput();

        input.gridId = gridSettings.gridId;
        input.gridName = gridSettings.gridName;
        input.gridConfig = JSON.stringify(gridConfig);

        this.gridStateServiceProxy.setGridState(input).subscribe(() => {
            gridSettings.isDefault = isDefault;
        });
    }

    logFeatureUsage(event: any, gridSettings: GridSettings, gridActionType: string): void {
        const gridConfig = this.extractGridConfig(gridSettings);
        const input = this.prepareTrackingDto(gridSettings, event, gridConfig);

        this.determineActionType(input, event, gridActionType);

        this.gridFeatureUsageTrackerServiceProxy.logGridFeatureUsage(input).subscribe();
    }

    private extractGridConfig(gridSettings: GridSettings): string {
        return JSON.stringify({
            columnsConfig: gridSettings.columnsConfig,
            state: gridSettings.state,
        });
    }

    private prepareTrackingDto(gridSettings: GridSettings, event: any, gridConfig: string): GridFeatureUsageTrackerDto {
        let dto = new GridFeatureUsageTrackerDto();

        if (Number.isInteger(gridSettings.gridId)) {
            dto.gridId = gridSettings.gridId;
        } else {
            dto.gridIdUuid = gridSettings.gridId;
        }

        dto.gridName = gridSettings.gridName;
        dto.gridConfig = gridConfig;
        dto.columnConfig = JSON.stringify(event);
        return dto;
    }

    private determineActionType(input: GridFeatureUsageTrackerDto, event: any, gridActionType: string): void {
        if ((input.columnConfig.includes('dir') && !input.columnConfig.includes('aggregate')) || gridActionType === GridActionType.SortChange || gridActionType === GridActionType.ResetToDefaultView) {
            this.handleSortAction(input, event, gridActionType);
        } else if (input.columnConfig.includes('filter') || gridActionType === GridActionType.FilterChange || gridActionType === GridActionType.ResetToDefaultView) {
            this.handleFilterAction(input, event);
        } else if (gridActionType === GridActionType.GroupCollapse) {
            this.handleGroupCollapseExpand(input, event, GridActionType.GroupCollapse);
        } else if (gridActionType === GridActionType.GroupExpand) {
            this.handleGroupCollapseExpand(input, event, GridActionType.GroupExpand);
        } else if (input.columnConfig.includes('aggregate') || gridActionType === GridActionType.GroupChange) {
            this.handleGroupAction(input, event);
        }
    }

    private handleSortAction(input: GridFeatureUsageTrackerDto, event: any, gridActionType: string): void {
        let countDir = 0; // Counter for descriptors with a 'dir'

        if (gridActionType !== GridActionType.ResetToDefaultView) {
            event?.forEach(descriptor => {
                if (descriptor.dir) {
                    countDir++;
                }
            });
        }

            const lastDescriptor = event[event.length - 1];

        input.gridActionCategory = 'sort';
        input.sortFilterGroupCount = countDir;

        if (lastDescriptor) {
            input.columnName = lastDescriptor.field;
        }

        if (!lastDescriptor || lastDescriptor.dir === undefined) {
            input.gridActionType = 'unsorted';
        } else {
            input.gridActionType = 'sorted';
            input.sortDirection = lastDescriptor.dir;
        }
    }

    private handleFilterAction(input: GridFeatureUsageTrackerDto, event: any): void {
        const filtersArray = event.filters;
        if (!Array.isArray(filtersArray)) {
            console.warn("Invalid event structure: 'filters' is not an array.");
            return;
        }

        input.gridActionCategory = 'filter';

        if (filtersArray.length > 0) {
            const lastFilterGroup = filtersArray[filtersArray.length - 1];
            if (!Array.isArray(lastFilterGroup.filters)) {
                console.warn("Invalid event structure: 'filters' in last group is not an array.");
                return;
            }

            const lastFilter = lastFilterGroup.filters[lastFilterGroup.filters.length - 1];
            input.columnName = lastFilter.field;

            const currentFilterCount = lastFilterGroup.filters.length;

            if (currentFilterCount < event.filterCountBeforeAction) { // Track if there were more filters before this action
                input.gridActionType = 'unfiltered'; // Include the current filter count
                input.sortFilterGroupCount = currentFilterCount;
            } else {
                input.gridActionType = 'filtered';
                input.sortFilterGroupCount = filtersArray.length;
            }
        } else {
            input.gridActionType = "unfiltered";
            input.columnName = null;
        }
    }

    private handleGroupAction(input: GridFeatureUsageTrackerDto, event: any): void {
        if (!Array.isArray(event)) {
            console.warn("Invalid event structure: event is not an array.");
            return;
        }

        const currentGroupCount = event.length; // Get the current group count

        input.gridActionCategory = 'group';

        if (currentGroupCount > 0) {
            const lastGroup = event[currentGroupCount - 1];
            const fieldName = lastGroup.field;
            input.gridActionType = 'grouped';
            input.sortDirection = lastGroup.dir;
            input.sortFilterGroupCount = currentGroupCount;
            input.columnName = fieldName;
        } else {
            input.gridActionType = 'ungrouped'; // Include the current group count (0)
            input.sortFilterGroupCount = currentGroupCount;
        }
    }

    private handleGroupCollapseExpand(input: GridFeatureUsageTrackerDto, event: any, gridActionType: string): void {
        input.gridActionCategory = 'group';
        input.gridActionType = gridActionType;
        input.columnName = event['groupIndex'] + '|' + event['group'].field + '|' + event['group'].value;
    }

    getClientAndLocationObservable(): Observable<ClientAndLocationDto> {
        return this.clientAndLocationServiceProxy.getClientAndLocation();
    }

    setClientAndLocation(input: SetClientAndLocationInput): void {
        this.clientAndLocationServiceProxy.setClientAndLocation(input).subscribe(() => { });
    }

    saveSelectedClientAndLocation(): void {
        let input = new SetClientAndLocationInput();

        input.clientIdGuid = this.appSession.selectedClientId;
        input.locationIdGuid = this.appSession.selectedLocationId;

        this.setClientAndLocation(input);
    }

    getDynamicPageStateObservable(): Observable<DynamicPageStateDto> {
        return this.dynamicPageStateServiceProxy.getDynamicPageState();
    }

    setDynamicPageState(input: DynamicPageStateDto): void {
        this.dynamicPageStateServiceProxy.setDynamicPageState(input).subscribe(() => { });
    }

    saveDynamicPageState(pageId: string, componentId: string, controlTypeId: number, value: string): void {
        let state = new DynamicPageStateDto();
        state.pageId = pageId;
        state.componentId = componentId;
        state.controlTypeId = controlTypeId;
        state.value = value;

        this.setDynamicPageState(state);

        let record = this.appSession.dynamicPageStates.filter(x => x.pageId === state.pageId && x.componentId === state.componentId);

        if (record[0]) {
            record[0].value = state.value;
        }
        else {
            this.appSession.dynamicPageStates.push(state);
        }
    }

    checkIfDefault(defaultGridSettings: GridSettings, currentGridSettings: GridSettings): boolean {
        //NOTE: For column sorting
        const defaultSorting = defaultGridSettings.state.sort || [];
        const currentSorting = currentGridSettings.state.sort || [];

        // Check if the length of sort arrays are the same
        if (defaultSorting.length !== currentSorting.length) {
            return false;
        }

        // Check each sort configuration in the same order
        for (let i = 0; i < defaultSorting.length; i++) {
            if (defaultSorting[i].field !== currentSorting[i].field || defaultSorting[i].dir !== currentSorting[i].dir) {
                return false; // If any sorting configuration doesn't match, it's not default
            }
        }

        //NOTE: For column visibility
        for (let i = 0; i < defaultGridSettings.columnsConfig.length; i++) {
            const defaultColumn = defaultGridSettings?.columnsConfig[i];
            const currentColumn = currentGridSettings?.columnsConfig[i];

            // 'hidden' property is considered false if it's not explicitly set to true
            const isDefaultColumnHidden = defaultColumn?.hidden === true; // true if hidden, false otherwise
            const isCurrentColumnHidden = currentColumn?.hidden === true; // true if hidden, false otherwise

            if (isDefaultColumnHidden !== isCurrentColumnHidden) {
                return false; // If visibility of any column has changed, it's not default
            }
        }

        //NOTE: For column filters
        const defaultFilters = defaultGridSettings.state.filter?.filters || [];
        const currentFilters = currentGridSettings.state.filter?.filters || [];

        // Check if the number of filters applied are the same
        if (defaultFilters.length !== currentFilters.length) {
            return false;
        }

        // Create a mapping of field to filter for easy comparison
        const mapFilters = (filters) => {
            return filters.reduce((map, filter) => {
                map[filter.field] = filter;
                return map;
            }, {});
        };

        const defaultFiltersMap = mapFilters(defaultFilters);
        const currentFiltersMap = mapFilters(currentFilters);

        // Check each filter for changes
        for (const field in defaultFiltersMap) {
            if (!currentFiltersMap[field] || JSON.stringify(defaultFiltersMap[field]) !== JSON.stringify(currentFiltersMap[field])) {
                return false; // If any filter doesn't match, it's not default
            }
        }

        //NOTE: For column size
        for (let i = 0; i < defaultGridSettings.columnsConfig.length; i++) {
            const defaultColumn = defaultGridSettings?.columnsConfig[i];
            const currentColumn = currentGridSettings?.columnsConfig[i];

            // Check if the size of the columns match
            // Assuming size is represented by '_width' property
            if (defaultColumn._width !== undefined) {
                if (defaultColumn._width !== currentColumn?._width) {
                    return false; // If size of any column doesn't match, it's not default
                }
            }
            else {
                if (defaultColumn.width !== currentColumn?.width) {
                    return false; // If size of any column doesn't match, it's not default
                }
            }
        }

        return true;
    }

    getGridStateObservable(gridSettings: GridSettings): Observable<GridStateDto> {
        return this.gridStateServiceProxy.getGridState(gridSettings.gridId);
    }

    setGridState(gridSettings: GridSettings, result: GridStateDto, defaultGridSettings: GridSettings): void {
        if (!!result?.gridConfig) {
            gridSettings.state = JSON.parse(result.gridConfig)['state'];
            gridSettings.columnsConfig = this.mapGridSettings(JSON.parse(result.gridConfig)['columnsConfig']);
            gridSettings.isDefault = this.checkIfDefault(defaultGridSettings, gridSettings);//NOTE: set to true if the column order changed otherwise set to false
        } else {
            gridSettings.isDefault = true;
        }

        for (var i = 0; i < gridSettings.state.group.length; i++) {
            let col = gridSettings.columnsConfig.find(col => col.field === gridSettings.state.group[i].field);

            this.groups.push({ field: gridSettings.state.group[i].field, editor: col.editor });
        }
    }

    mapGridSettings(columnsConfig: ColumnSettings[]): ColumnSettings[] {
        return columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex);
    }

    removeGridStateObservable(gridSettings: GridSettings): Observable<void> {
        return this.gridStateServiceProxy.removeGridState(gridSettings.gridId);
    }

    setGridStateObservable(input: SetGridStateInput): Observable<void> {
        return this.gridStateServiceProxy.setGridState(input);
    }

    customResetToDefaultView(gridSettings: GridSettings, defaultGridSettings: GridSettings): SetGridStateInput {
        // Reset only sorting, visible columns, filters
        // Preserve grouping, column order, and pagination settings

        // Copy over the default sorting, filter and column configuration
        const newState: State = {
            ...gridSettings.state,
            sort: defaultGridSettings.state.sort,
            filter: defaultGridSettings.state.filter,
            group: gridSettings.state.group // Preserve existing grouping
        };

        // Update column configuration to the default settings.
        //const updatedColumnsConfig = gridSettings.columnsConfig.map(column => {
        //    const defaultColumn = defaultGridSettings.columnsConfig.find(dc => dc.field === column.field);
        //    if (defaultColumn) {
        //        return {
        //            ...defaultColumn,
        //            title: defaultColumn.title,
        //            hidden: defaultColumn.hidden,
        //            orderIndex: defaultColumn.orderIndex,
        //            width: defaultColumn.width,
        //            _width: defaultColumn._width // Reset column size
        //        };
        //    }
        //    return column;
        //});

        // Update grid settings
        gridSettings.columnsConfig = defaultGridSettings.columnsConfig;
        gridSettings.state = newState;

        let input = new SetGridStateInput();

        input.gridId = gridSettings.gridId;
        input.gridName = gridSettings.gridName;
        input.gridConfig = JSON.stringify(gridSettings);

        return input;
    }

    customResetToDefaultViewAfter(gridSettings: GridSettings, defaultGridSettings: GridSettings, disbursements: any[], isServerSidePaging: any = null, data = null): void {
        // Reset only sorting, visible columns, filters
        // Preserve grouping, column order, and pagination settings

        // Copy over the default sorting, filter and column configuration
        const newState: State = {
            ...gridSettings.state,
            sort: defaultGridSettings.state.sort,
            filter: defaultGridSettings.state.filter,
            group: gridSettings.state.group // Preserve existing grouping
        };

        if (!isServerSidePaging) {
            gridSettings.gridData = process(disbursements, newState);
        } else {
            gridSettings.gridData = data;
        }

        gridSettings.isDefault = true;
    }

    resetToDefaultView(gridSettings: GridSettings, defaultGridSettings: GridSettings, disbursements: any[]): void {
        // Reset only sorting, visible columns, filters
        // Preserve grouping, column order, and pagination settings

        // Copy over the default sorting, filter and column configuration
        const newState: State = {
            ...gridSettings.state,
            sort: defaultGridSettings.state.sort,
            filter: defaultGridSettings.state.filter,
            group: gridSettings.state.group // Preserve existing grouping
        };

        // Update column configuration, only changing visibility and order
        //const updatedColumnsConfig = gridSettings.columnsConfig.map(column => {
        //    const defaultColumn = defaultGridSettings.columnsConfig.find(dc => dc.field === column.field);
        //    if (defaultColumn) {
        //        return {
        //            ...column,
        //            hidden: defaultColumn.hidden,
        //            orderIndex: defaultColumn.orderIndex
        //        };
        //    }
        //    return column;
        //});

        // Update grid settings
        gridSettings.columnsConfig = defaultGridSettings.columnsConfig;
        gridSettings.state = newState;

        let input = new SetGridStateInput();

        input.gridId = gridSettings.gridId;
        input.gridName = gridSettings.gridName;
        input.gridConfig = JSON.stringify(gridSettings);

        this.gridStateServiceProxy.setGridState(input).subscribe(() => {
            gridSettings.gridData = process(disbursements, newState);
            gridSettings.isDefault = true;
        });
    }

    setCorrectPagination(itemCount: number, gridSettings: GridSettings, reset: boolean = false) {
        const take = gridSettings.state.take;
        const skip = gridSettings.state.skip;

        let newSkip = Math.round(itemCount / take) * take;

        if (itemCount === newSkip && newSkip > 0) {
            newSkip = newSkip - take;
        }

        if (reset) {
            newSkip = 0;
        }

        if (skip > newSkip) {
            gridSettings.state.skip = newSkip;
            this.saveGridState(gridSettings);
        }
    }

    // Disbursement Details
    getDisbursementDetails(e): Observable<DisbursementDto> {
        return this.disbursementDetailsServiceProxy.getDisbursementDetails(
            e.dataItem.billNumber,
            this.convertToISODate(e.dataItem.billDate),
            e.dataItem.invoiceAmount.toString(),
            e.dataItem.archimedesDocumentId);
    }

    removeColumn(gridSettings: GridSettings, defaultGridSettings: GridSettings, columnName: string): void {
        gridSettings.columnsConfig = gridSettings.columnsConfig.filter(x => x.field !== columnName);
        defaultGridSettings.columnsConfig = defaultGridSettings.columnsConfig.filter(x => x.field !== columnName);
    }

    hideAmountRemainingColumnForSage(gridSettings: GridSettings): GridSettings {
        gridSettings.columnsConfig = gridSettings.columnsConfig.filter(x => x.field !== "amountRemaining");

        return gridSettings;
    }

    hideCheckNumberColumnForSage(gridSettings: GridSettings): GridSettings {
        gridSettings.columnsConfig = gridSettings.columnsConfig.filter(x => x.field !== "checkNumber");

        return gridSettings;
    }

    hideDueDateColumnForSPiN(gridSettings: GridSettings): GridSettings {
        gridSettings.columnsConfig = gridSettings.columnsConfig.filter(x => x.field !== "dueDate");

        return gridSettings;
    }

    rowCallback(context: RowClassArgs) {
        if (context.dataItem?.sls_inbox$is_urgent) {
            return { "urgent-item": true };
        }

        if (context.dataItem?.invoiceAmount < 0) {
            return { "negative-invoice": true };
        }
        else {
            return {
                dragging: context.dataItem?.dragging,
            };
        }
    }

    getFormattedTotalPaymentAmount(data: any[], distinctPaymetAmountByCheckNumber: boolean = true): string {
        let formatPaymentAmount = 0;

        if (distinctPaymetAmountByCheckNumber) {
            const dataWithoutUnassignedCheckNumber = data.filter(x => x.checkNumber !== "");
            const dataWithUnassignedCheckNumber = data.filter(x => x.checkNumber === "");
            const distinctDataWithoutUnassignedCheckNumber = distinct(dataWithoutUnassignedCheckNumber, "checkNumber");
            distinctDataWithoutUnassignedCheckNumber.push(...dataWithUnassignedCheckNumber);
            const distinctData = distinctDataWithoutUnassignedCheckNumber;
            const distinctAggregateResult = aggregateBy(distinctData, this.distinctAggregates);
            formatPaymentAmount = distinctAggregateResult["paymentAmount"] === undefined ? 0 : distinctAggregateResult["paymentAmount"].sum;
        } else {
            const aggregateResult = aggregateBy(data, this.distinctAggregates);
            formatPaymentAmount = aggregateResult["paymentAmount"] === undefined ? 0 : aggregateResult["paymentAmount"].sum;
        }

        return this.formatNumber(formatPaymentAmount);
    }

    confirmStatusChange(data: any[], changeAll: boolean, action: string, callback: (result: boolean) => void, confirmTitle: string = "", confirmMessage: string = "", distinctPaymetAmountByCheckNumber: boolean = true, customCss = null): void {
        if (data.length > 0) {
            const formattedTotalPaymentAmount = this.getFormattedTotalPaymentAmount(data, distinctPaymetAmountByCheckNumber);

            if (confirmTitle === "") {
                confirmTitle = changeAll ? this.l(action + 'All') : data.length > 1 ? this.l(action + 'Payments') : this.l(action + 'Payment');
            }

            if (confirmMessage === "") {
                confirmMessage = data.length > 1 ? this.l('ActionPaymentsAreYouSure', action.toLowerCase(), data.length, formattedTotalPaymentAmount) : this.l('ActionPaymentAreYouSure', action.toLowerCase(), formattedTotalPaymentAmount);
            }

            this.message.confirm(
                confirmMessage,
                confirmTitle,
                callback,
                customCss
            );
        } else {
            this.message.info("", this.l('NoRecordsAvailable'), {
                isHtml: false,
                confirmButtonText: this.l('Ok')
            });
        }
    }

    applyConfirmMessage(action: string, size: number, formattedPayment: string): string {
        if (action.toLowerCase().indexOf("select") !== -1 || action.toLowerCase().indexOf("approve") !== -1) {
            if (size > 1) {
                return this.l('ActionPaymentsAreYouSure', action.toLowerCase(), size, formattedPayment);
            } else {
                return this.l('ActionPaymentAreYouSure', action.toLowerCase(), formattedPayment);
            }
        } else {
            if (action.toLowerCase().indexOf("un") !== -1) {
                return this.l("UnsyncAllAreYouSure");
            } else {
                return this.l("SyncAllAreYouSure");
            }
        }
    }

    movePayments(isAllPayments: boolean, highlightedPaymentIds: string[], sourcePayments: DisbursementDto[], paymentsToBeMoved: DisbursementDto[]): [DisbursementDto[], DisbursementDto[]] {
        let addToDestination: DisbursementDto[] = [];
        let newSourcePayments: DisbursementDto[] = [];

        if (isAllPayments) {
            addToDestination.push(...paymentsToBeMoved);
            newSourcePayments = [];
        } else {
            const multiselected = highlightedPaymentIds.length > 0;

            if (this.isGranted('Pages.Sage') || this.isGranted('Pages.Intacct')) {
                if (multiselected) {
                    addToDestination.push(...sourcePayments.filter(x => highlightedPaymentIds.indexOf(x.id) !== -1));
                    newSourcePayments = sourcePayments.filter(x => highlightedPaymentIds.indexOf(x.id) === -1);
                } else {
                    addToDestination.push(paymentsToBeMoved[0]);
                    newSourcePayments = sourcePayments.filter(x => x.id !== paymentsToBeMoved[0].id);
                }
            } else {
                if (multiselected) {
                    const highlightedPayments = sourcePayments.filter(x => highlightedPaymentIds.indexOf(x.id) !== -1);
                    const highlightedPaymentsCheckNumber = distinct(highlightedPayments, "checkNumber").map(x => x.checkNumber);

                    addToDestination.push(...sourcePayments.filter(x => highlightedPaymentsCheckNumber.indexOf(x.checkNumber) !== -1));
                    newSourcePayments = sourcePayments.filter(x => highlightedPaymentsCheckNumber.indexOf(x.checkNumber) === -1);
                } else {
                    addToDestination.push(...sourcePayments.filter(x => x.checkNumber === paymentsToBeMoved[0].checkNumber));
                    newSourcePayments = sourcePayments.filter(x => x.checkNumber !== paymentsToBeMoved[0].checkNumber);
                }
            }
        }

        return [addToDestination, newSourcePayments];
    }

    flagUnsavedChanges(payments: any[], isSelected: boolean): boolean {
        return payments.filter(x => x.isSelectedInBL !== isSelected).length > 0;
    }

    gridIsLoading(isLoading: boolean = true): void {
        if (this.loadingStateService.isLoadingEnabled) {
            this.loadingStateService.isLoading = isLoading;
        }
    }

    isItemSelected(source: any[], id: number): boolean {
        if (source === undefined) {
            return false;
        }

        return source.some(item => item === id);
    }

    retrieveMinItemLength(currentPage: number, take: number, total: number) {
        let sum = (currentPage - 1) * take + 1

        if (total === 0) {
            sum = total
        }

        return sum;
    }

    retrieveMaxItemLength(currentPage: number, take: number, total: number) {
        let sum = (currentPage - 1) * take + take

        if (sum > total) {
            sum = total;
        }

        return sum;
    }

    applyFilteredText(items: any, total: number) {
        return items != undefined && items.length != total ? "filtered" : "";
    }

    serverSidePagingApplyFilteredText(total: number, totalFiltered: number, hasGridFilter: boolean) {
        return (totalFiltered != 0 && total != totalFiltered) || hasGridFilter ? "filtered" : "";
    }

    iterateNestedArrayForSumItems(data: any): void {
        if (data.id !== undefined && this.gridItems.length < 1) {
            this.gridItems.push(data);
        } else if (data.items !== undefined) {
            data.items.map(x => {
                this.iterateNestedArrayForSumItems(x);
            });
        }
    }

    getGridFilteredItemsForSumItems(data: any): void {
        this.gridItems = [];
        data.map(x => {
            this.iterateNestedArrayForSumItems(x);
        })
    }

    gridFilteredPaymentIds: string[] = [];

    getCheckedFilteredPayments<T>(checkedPyments: T[], filter: CompositeFilterDescriptor): T[] {
        return filterBy(checkedPyments, filter);
    }

    getGridFilteredPaymentIds(data: any): void {
        this.gridFilteredPaymentIds = [];
        data.map(x => {
            this.iterateNestedArray(x);
        });
    }

    iterateNestedArray(data: any): void {
        if (data.id !== undefined) {
            this.gridFilteredPaymentIds.push(data.id);
        } else {
            data.items.map(x => {
                this.iterateNestedArray(x);
            });
        }
    }

    getGridFilteredPayments<T>(payments: T[]): T[] {
        let filteredPayments: T[] = [];
        payments.map(x => {
            if (this.gridFilteredPaymentIds.indexOf(x["id"]) !== -1) {
                filteredPayments.push(x);
            }
        });
        return filteredPayments;
    }

    getRemainingCheckedPayments<T>(payments: T[]): T[] {
        let filteredPayments: T[] = [];
        payments.map(x => {
            if (this.gridFilteredPaymentIds.indexOf(x["id"]) === -1) {
                filteredPayments.push(x);
            }
        });
        return filteredPayments;
    }

    getOnlyGridFilteredPaymentNotes(paymentNotes: PaymentNoteDto[]): PaymentNoteDto[] {
        let filteredPaymentNotes: PaymentNoteDto[] = [];
        paymentNotes.map(x => {
            if (this.gridFilteredPaymentIds.indexOf(x.disbursementId) !== -1) {
                filteredPaymentNotes.push(x);
            }
        });
        return filteredPaymentNotes;
    }

    getOnlyGridFilteredPaymentsForUpdate(paymentsForUpdate: PaymentForUpdate[]): PaymentForUpdate[] {
        let filteredPaymentsForUpdate: PaymentForUpdate[] = [];
        paymentsForUpdate.map(x => {
            if (this.gridFilteredPaymentIds.indexOf(x.disbursementId) !== -1) {
                filteredPaymentsForUpdate.push(x);
            }
        });
        return filteredPaymentsForUpdate;
    }

    getOnlyPaymentsThatAreFiltered(ids: string[]) {
        let paymentsThatAreFiltered: string[] = [];
        paymentsThatAreFiltered.push(...ids.filter(x => this.gridFilteredPaymentIds.indexOf(x) !== -1));
        return paymentsThatAreFiltered;
    }

    getOnlyPaymentsThatAreFilteredReturnAllFields(payments: any[]) {
        let paymentsThatAreFiltered: any[] = [];
        paymentsThatAreFiltered.push(...payments.filter(x => this.gridFilteredPaymentIds.indexOf(x.id) !== -1));
        return paymentsThatAreFiltered;
    }

    getOnlyPaymentsThatAreHiddenDueToFilter(ids: string[]) {
        let paymentsThatAreFiltered: string[] = [];
        paymentsThatAreFiltered.push(...ids.filter(x => this.gridFilteredPaymentIds.indexOf(x) === -1));
        return paymentsThatAreFiltered;
    }

    getIndexofAddedGroupFromOldState(oldState: GroupDescriptor[], newState: GroupDescriptor[]): number {
        if (newState.length > oldState.length) {
            for (var i = 0; i < oldState.length; i++) {
                if (oldState[i].field !== newState[i].field) {
                    return i;
                }
            }
        }

        return -1;
    }

    roundTo2DecimalPlaces(num: number): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    unhideGroupFooter(group): boolean {
        let groupIndex = this.groups.findIndex(g => g.field === group.field);
        if (groupIndex > 4) {
            return false;
        }
        else {
            return true;
        }
    }

    clearDictionaries(): void {
        this.invoiceAmountDictionary = {};
        this.discountAmountDictionary = {};
        this.amountRemainingDictionary = {};
        this.paymentAmountDictionary = {};
        this.checkAmountDictionary = {};
    }

    getFirstUnhiddenIndex(gridSettings: GridSettings): number {
        if (gridSettings !== undefined) {
            for (let i = 0; i < gridSettings.columnsConfig.length; i++) {
                if (gridSettings.columnsConfig[i].hidden === undefined || gridSettings.columnsConfig[i].hidden === false) {
                    return i;
                }
            }
        }

        return 0;
    }

    isBillDateFutureDate(item: any): boolean {
        return item.billDate > new Date();
    }

    convertToISODate(inputDate: Date): string {
        const offset = inputDate.getTimezoneOffset();
        inputDate = new Date(inputDate.getTime() - (offset * 60 * 1000));

        return inputDate.toISOString().split('T')[0];
    }

    formatItemTextToBoolean(item, gridStructure) {
        if (gridStructure !== undefined) {
            for (var key in item) {
                var currentKey = gridStructure.items.find(x => x.field === key);
                if (currentKey !== undefined && currentKey.controlTypeId.controlTypeName === this.controlTypeNames.CheckBox) {
                    if (typeof item[key] === 'string') {
                        item[key] = item[key].toLowerCase() === 'true' ? true : false;
                    }
                }
            }
        }

        return item;
    }

    formatItemTextToComboBoxData(item, comboBoxData) {
        for (var key in item) {
            if (comboBoxData[key] !== undefined) {
                var parsedValue = comboBoxData[key].find(x => x.name === item[key])?.id;
                if (parsedValue !== undefined) {
                    item[key] = parsedValue;
                }
            }
        }

        return item;
    }

    isGUID(value) {
        let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

        return regex.test(value);
    }

    formatItemDateWithoutId(item, gridStructure = undefined) {
        let regex = /[0-9]+-(0?[1-9]|[1][0-2])-[0-9]+T[0-9]+:[0-9]+:([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/i;

        if (gridStructure !== undefined) {
            for (var key in item) {
                var currentKey = gridStructure.items.find(x => x.field === key);
                if (currentKey !== undefined && currentKey.controlTypeId.controlTypeName === this.controlTypeNames.Date) {
                    if ((key.toLowerCase().includes("date") === true && key.includes("Id") === false && item[key] instanceof Date === false) || regex.test(item[key])) {
                        var dateValue = new Date(DateTime.fromISO(item[key]).toJSDate().toDateString());
                        item[key] = item[key] ? isNaN(dateValue.getDate()) ? item[key] : dateValue : <any>undefined;
                    }
                }
            }
        }
        else {
            for (var key in item) {
                if ((key.toLowerCase().includes("date") === true && key.includes("Id") === false && item[key] instanceof Date === false) || regex.test(item[key])) {
                    var dateValue = new Date(DateTime.fromISO(item[key]).toJSDate().toDateString());
                    item[key] = item[key] ? isNaN(dateValue.getDate()) ? item[key] : dateValue : <any>undefined;
                }
            }
        }

        return item;
    }

    formatItemDate(item) {
        for (var key in item) {
            if (key.toLowerCase().includes("date") === true && typeof item[key] === 'object') {
                item[key] = item[key] ? new Date(DateTime.fromISO(item[key]).toJSDate().toDateString()) : <any>undefined;
            }
        }

        return item;
    }

    formatItemDateNonObject(item) {
        for (var key in item) {
            if (key.toLowerCase().includes("date") === true) {
                item[key] = item[key] ? new Date(DateTime.fromISO(item[key]).toJSDate().toDateString()) : <any>undefined;
            }
        }

        return item;
    }

    getGridContextMenuItems(componentSettings: any, gridStructureDataSourceName: string = "", parentItem: any = undefined, items: any = undefined, rightClickedDataItem: any = undefined): any {
        let gridName = "";
        if (componentSettings?.gridName !== null && componentSettings?.gridName !== undefined) {
            gridName = componentSettings?.gridName;
        }
        else {
            gridName = gridStructureDataSourceName;
        }

        let contextMenuItems = [];

        if (componentSettings !== null && componentSettings !== undefined) {
            if (componentSettings.hasPopUpForm !== undefined && componentSettings.hasPopUpForm) {
                contextMenuItems.push(this.l('OpenPopUp'));
            }

            if (componentSettings.allowDelete !== undefined && componentSettings.allowDelete) {
                contextMenuItems.push(this.l('DeleteItem'));
            }

            if (componentSettings.gridName === "JayGridDemo") {
                contextMenuItems.push(this.l("PopupDialog"));
            }
        }

        if (parentItem !== null && parentItem !== undefined) {
            if (gridName === AppConsts.dynamic.DynamicNavigationMaintenance) {
                contextMenuItems.push(this.l("MoveToParent"));
            }
        }

        if (gridName === AppConsts.dynamic.DynamicNavigationMaintenance && items?.length > 1) {
            contextMenuItems.push(this.l("MoveToChild"));
        }

        if (gridStructureDataSourceName === "GridStructureArrayData") {
            contextMenuItems.push(
                this.l('DeleteItem')
            );

            return contextMenuItems;
        }

        //TODO: Move this to database
        switch (gridName) {
            case AppConsts.dynamic.DynamicNavigationMaintenance:
                contextMenuItems.push(
                    this.l('MoveUp'),
                    this.l('MoveDown'),
                    this.l('DeleteMenu')
                );

                if (rightClickedDataItem?.id !== "") {
                    contextMenuItems.splice(2, 0, this.l('CloneMenu'));
                }
                break;
            case AppConsts.dynamic.DynamicPageMaintenance:
                if (rightClickedDataItem?.id !== "") {
                    contextMenuItems.push(
                        this.l('GoToDynamicPageDesignMaintenance'),
                        this.l('GoToPageLayoutDefinitions'),
                        this.l('GoToPage'),
                        this.l('ClonePage')
                    );
                }

                contextMenuItems.push(this.l('DeletePage'));
                break;
            case AppConsts.dynamic.DynamicTabMaintenance:
                if (rightClickedDataItem?.id !== "") {
                    contextMenuItems.push(
                        this.l('GoToDynamicTabDesignMaintenance'),
                        this.l('GoToPageLayoutDefinitions')
                    );
                }

                contextMenuItems.push(
                    this.l('CloneTab'),
                    this.l('DeleteTab')
                );
                break;
            case AppConsts.dynamic.DynamicFormMaintenance:
                if (rightClickedDataItem?.id !== "") {
                    contextMenuItems.push(
                        this.l('GoToDynamicFormDesignMaintenance'),
                        this.l('CloneForm')
                    );
                }

                contextMenuItems.push(this.l('DeleteForm'));
                break;
            case AppConsts.dynamic.DynamicGridMaintenance:
                if (rightClickedDataItem?.id !== "") {
                    contextMenuItems.push(
                        this.l('GoToDynamicGridDesignMaintenance'),
                        this.l('CloneGrid')
                    );
                }

                contextMenuItems.push(this.l('DeleteGrid'));
                break;
            case AppConsts.dynamic.DynamicActionButtonMaintenance:
                if (rightClickedDataItem?.id !== "") {
                    contextMenuItems.push(this.l('CloneActionButton'));
                }

                contextMenuItems.push(this.l('DeleteActionButton'));
                break;
            case AppConsts.dynamic.DynamicFormDesignMaintenance:
                contextMenuItems.push(
                    this.l('MoveUp'),
                    this.l('MoveDown'),
                    this.l('DeleteGroupFieldSet')
                );
                break;
            case AppConsts.dynamic.DynamicFieldMaintenance:
                contextMenuItems.push(
                    this.l('FormControlTypeOptionAndValidation'),
                    this.l('MoveUp'),
                    this.l('MoveDown'),
                    this.l('DeleteField')
                );
                break;
            case AppConsts.dynamic.DynamicFormChildGroupFieldSets:
                contextMenuItems.push(
                    this.l('MoveUp'),
                    this.l('MoveDown'),
                    this.l('DeleteChildGroupFieldSet')
                );
                break;
            case AppConsts.dynamic.DynamicGridDesignMaintenance:
                contextMenuItems.push(
                    this.l('GridControlTypeOptionAndValidation'),
                    this.l('MoveUp'),
                    this.l('MoveDown'),
                    this.l('DeleteField'),
                    this.l('PreviewGrid')
                );
                break;
            case AppConsts.dynamic.AttachDetachDynamicGridComponent:
                contextMenuItems.push(
                    this.l('GoToDynamicGridDesignMaintenance'),
                    this.l('DetachGrid')
                );
                break;
            case AppConsts.dynamic.AttachDetachDynamicFormComponent:
                contextMenuItems.push(
                    this.l('GoToDynamicFormDesignMaintenance'),
                    this.l('DetachForm')
                );
                break;
            case AppConsts.dynamic.AttachDetachDynamicActionButtonComponent:
                contextMenuItems.push(
                    this.l('DetachActionButton')
                );
                break;
            case AppConsts.dynamic.AttachDetachDynamicTabComponent:
                contextMenuItems.push(
                    this.l('DetachTab')
                );
                break;
            case AppConsts.dynamic.OneTimePayments:
                contextMenuItems.push(
                    this.l('ManageAccountEntries')
                );
                break;
            case AppConsts.dynamic.GridStructureNumericControlTypeValidation:
            case AppConsts.dynamic.GridStructureDateControlTypeValidation:
            case AppConsts.dynamic.GridStructureRadioButtonControlTypeValidation:
                contextMenuItems.push(
                    this.l('DeleteItem')
                );
                break;
            case AppConsts.dynamic.GridStructureFormArrayDataControlTypeValidation:
                contextMenuItems.push(
                    this.l('DeleteItem')
                );
                break;
            default:
                return contextMenuItems;
        }

        return contextMenuItems;
    }

    isKendoEditor(editor: string) {
        if (editor === "combobox" || editor === "date" || editor === "numeric" || editor === "dropdown") {
            return true;
        }
        else {
            return false;
        }
    }

    isKendoInput(controlTypeId: number) {
        if (controlTypeId === 2 || controlTypeId === 3 || controlTypeId === 4 || controlTypeId === 5 || controlTypeId === 6 || controlTypeId === 9) {
            return true;
        }
        else {
            return false;
        }
    }

    dateLessThan(dateField: string, minDate: any, useMinimumDate: boolean = false): ValidatorFn {
        return (formGroup: FormGroup): { [key: string]: boolean } | null => {
            const dateValue = formGroup.get(dateField) !== null ? formGroup.get(dateField).value : formGroup.value;
            if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
            }

            let minimumDateToCompare = undefined;
            if (!useMinimumDate) {
                minimumDateToCompare = formGroup.get(minDate)?.value;
            }
            else {
                minimumDateToCompare = new Date(minDate);
            }
            minimumDateToCompare?.setHours(0, 0, 0, 0);

            if ((dateValue !== null && minimumDateToCompare !== null) && dateValue < minimumDateToCompare) {
                let validatorResult = {};
                if (formGroup !== undefined) {
                    validatorResult['dateGrid'] = { minimumDate: minimumDateToCompare };
                    if (formGroup?.controls !== undefined) {
                        formGroup.controls[dateField].setErrors(validatorResult);
                    }
                    else {
                        formGroup.setErrors(validatorResult);
                    }
                }

                return validatorResult;
            }
            else {
                if (formGroup?.controls !== undefined && formGroup?.controls[dateField]?.errors && formGroup?.controls[dateField]?.errors['dateGrid']) {
                    delete formGroup.controls[dateField].errors['dateGrid'];
                    if (Object.keys(formGroup.controls[dateField].errors).length === 0) {
                        const errors = omit(formGroup.controls[dateField].errors, ['dateGrid']);
                        formGroup.controls[dateField].setErrors(errors);
                        formGroup.controls[dateField].updateValueAndValidity();
                    }
                }
                else {
                    if (formGroup?.errors !== null && formGroup?.errors?.length > 0) {
                        const errors = omit(formGroup.errors, ['dateGrid']);
                        formGroup.setErrors(errors);
                        formGroup.updateValueAndValidity();
                    }
                }

                return null;
            }
        };
    }

    dateGreaterThan(dateField: string, maxDate: any, useMaximumDate: boolean = false): ValidatorFn {
        return (formGroup: FormGroup): any => {
            const dateValue = formGroup.get(dateField) !== null ? formGroup.get(dateField).value : formGroup.value;
            if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
            }

            let maximumDateToCompare = undefined;
            if (!useMaximumDate) {
                maximumDateToCompare = formGroup.get(maxDate).value;
            }
            else {
                maximumDateToCompare = new Date(maxDate);
            }
            maximumDateToCompare.setHours(0, 0, 0, 0);

            if ((dateValue !== null && maximumDateToCompare !== null) && maximumDateToCompare <= dateValue) {
                let validatorResult = {};
                if (formGroup !== undefined) {
                    validatorResult['dateGrid'] = { maximumDate: maximumDateToCompare };
                    if (formGroup?.controls !== undefined) {
                        formGroup.controls[dateField].setErrors(validatorResult);
                    }
                    else {
                        formGroup.setErrors(validatorResult);
                    }
                }

                return validatorResult;
            }
            else {
                if (formGroup?.controls !== undefined && formGroup?.controls[dateField]?.errors && formGroup?.controls[dateField]?.errors['dateGrid']) {
                    delete formGroup.controls[dateField].errors['dateGrid'];
                    if (Object.keys(formGroup.controls[dateField].errors).length === 0) {
                        const errors = omit(formGroup.controls[dateField].errors, ['dateGrid']);
                        formGroup.controls[dateField].setErrors(errors);
                        formGroup.controls[dateField].updateValueAndValidity();
                    }
                }
                else {
                    if (formGroup?.errors !== null && formGroup?.errors?.length > 0) {
                        const errors = omit(formGroup.errors, ['dateGrid']);
                        formGroup.setErrors(errors);
                        formGroup.updateValueAndValidity();
                    }
                }

                return null;
            }
        };
    }

    dateInRange(dateField: string, minDate: any, maxDate: any, useMinimumDate: boolean = false, useMaximumDate: boolean = false): ValidatorFn {
        return (formGroup: FormGroup): any => {
            let dateValue = formGroup.get(dateField) !== null ? formGroup.get(dateField).value : formGroup.value;
            if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
            }

            let minimumDateToCompare = undefined;
            if (!useMinimumDate) {
                minimumDateToCompare = formGroup.get(minDate).value;
            }
            else {
                minimumDateToCompare = new Date(minDate);
            }
            minimumDateToCompare.setHours(0, 0, 0, 0);

            let maximumDateToCompare = undefined;
            if (!useMaximumDate) {
                maximumDateToCompare = formGroup.get(maxDate).value;
            }
            else {
                maximumDateToCompare = new Date(maxDate);
            }
            maximumDateToCompare.setHours(0, 0, 0, 0);

            const dateNotWithinRange = (dateValue !== null && minimumDateToCompare !== null && maximumDateToCompare !== null) && !(maximumDateToCompare >= dateValue && dateValue >= minimumDateToCompare);
            if (dateNotWithinRange) {
                let validatorResult = {};
                if (formGroup !== undefined) {
                    validatorResult['dateGrid'] = { minimumDate: minimumDateToCompare, maximumDate: maximumDateToCompare };
                    if (formGroup?.controls !== undefined && formGroup?.errors?.length > 0) {
                        formGroup.controls[dateField].setErrors(validatorResult);
                    }
                    else {
                        formGroup.setErrors(validatorResult);
                    }
                }

                return validatorResult;
            }
            else {
                if (formGroup?.controls !== undefined && formGroup?.controls[dateField]?.errors && formGroup?.controls[dateField]?.errors['dateGrid']) {
                    delete formGroup.controls[dateField].errors['dateGrid'];
                    if (Object.keys(formGroup.controls[dateField].errors).length === 0) {
                        const errors = omit(formGroup.controls[dateField].errors, ['dateGrid']);
                        formGroup.controls[dateField].setErrors(errors);
                        formGroup.controls[dateField].updateValueAndValidity();
                    }
                }
                else {
                    if (formGroup?.errors !== null && formGroup?.errors?.length > 0) {
                        const errors = omit(formGroup.errors, ['dateGrid']);
                        formGroup.setErrors(errors);
                        formGroup.updateValueAndValidity();
                    }
                }

                return null;
            }
        };
    }

    passwordMatchValidator(field: string) {
        return (formGroup: FormGroup) => formGroup.get(field)?.value === formGroup.get('confirmPassword')?.value
            ? null : { 'mismatch': true };
    }

    numericGridValidator(numericField: string, numericGrid: any): ValidatorFn {
        return (formGroup: FormGroup): { [key: string]: any } | null => {
            let total = 0;
            let previousOperator = undefined;
            let comparator = undefined;
            for (let numericComparator of numericGrid) {
                let currentValue = formGroup.get(numericComparator.numericColumnName)?.value;
                if (currentValue !== undefined) {
                    if (numericGrid.indexOf(numericComparator) === 0) {
                        total = currentValue;
                    }

                    if (previousOperator !== undefined) {
                        if (previousOperator === AppConsts.numericOperators.addition) {
                            total += currentValue;
                        }
                        else if (previousOperator === AppConsts.numericOperators.subtraction) {
                            total -= currentValue;
                        }
                        else if (previousOperator === AppConsts.numericOperators.multiplication) {
                            total *= currentValue;
                        }
                        else if (previousOperator === AppConsts.numericOperators.division) {
                            total /= currentValue;
                        }
                    }

                    previousOperator = numericComparator.operator;
                    if (previousOperator === AppConsts.numericOperators.lessThan || previousOperator === AppConsts.numericOperators.greaterThan) {
                        comparator = previousOperator;
                        break;
                    }
                }
                else return null;
            }

            const invalidLessThanFlag = comparator === AppConsts.numericOperators.lessThan && formGroup.get(numericField)?.value < total;
            const invalidGreaterThanFlag = comparator === AppConsts.numericOperators.greaterThan && formGroup.get(numericField)?.value > total;

            if (invalidLessThanFlag || invalidGreaterThanFlag) {
                let validatorResult = {};
                validatorResult['numericGrid'] = { comparatorValue: comparator, totalValue: total };
                formGroup.controls[numericField].setErrors(validatorResult);
                return validatorResult;
            }
            else {
                if (formGroup.controls[numericField]?.errors && formGroup.controls[numericField]?.errors['numericGrid']) {
                    delete formGroup.controls[numericField].errors['numericGrid'];
                    if (formGroup?.controls[numericField].errors !== null || Object.keys(formGroup.controls[numericField].errors).length === 0) {
                        const errors = omit(formGroup.controls[numericField].errors, ['numericGrid']);
                        formGroup.controls[numericField].setErrors(errors);
                        formGroup.controls[numericField].updateValueAndValidity();
                    }
                }

                return null;
            }
        };
    }

    isDynamicPages(pageName: string): boolean {
        return (pageName === "DynamicNavigationMaintenance" ||
            pageName === "DynamicPageMaintenance" ||
            pageName === "DynamicPageDesignMaintenance" ||
            pageName === "DynamicTabMaintenance" ||
            pageName === "DynamicTabDesignMaintenance" ||
            pageName === "DynamicFormMaintenance" ||
            pageName === "DynamicGridMaintenance" ||
            pageName === "DynamicActionButtonMaintenance" ||
            pageName === "DynamicGridDesignMaintenance" ||
            pageName === "DynamicFormDesignMaintenance");
    }

    isStreamlineaPages(pageName: string): boolean {
        return !this.isDynamicPages(pageName);
    }

    getCurrentTimeAsAString(date: Date = undefined) {
        // Get the current date
        var currentDate = new Date();
        if (date !== undefined) {
            currentDate = date;
        }

        // Get the timezone offset in minutes
        var offsetMinutes = currentDate.getTimezoneOffset();

        // Convert the offset to hours and minutes
        var offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
        var offsetMinutes = Math.abs(offsetMinutes % 60);

        // Determine the sign of the offset
        var offsetSign = offsetMinutes < 0 ? "+" : "-";

        // Format the timezone string
        var timezoneString = "GMT" + offsetSign + this.pad(offsetHours) + ":" + this.pad(offsetMinutes);

        // Format the date string
        var formattedDate = currentDate.toISOString().replace('T', ' ').replace('Z', ' ' + timezoneString);

        return formattedDate;
    }

    // Function to pad single digit numbers with leading zeros
    pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    formatItemDateRecursive(item) {
        item = this.formatItemDateNonObject(item);

        if (item.items && Array.isArray(item.items)) {
            item.items.forEach(nestedItem => {
                this.formatItemDateRecursive(nestedItem);
            });
        }
    }

    hideLocationColumnIfThereIsOnlyOneEntityWithOneLocation(locationsCount: number, defaultGridSettings: GridSettings, gridSettings: GridSettings): void {
        const hasOnlyOneLocation = locationsCount <= 1;
        if (this.appSession.hideEntityColumn && hasOnlyOneLocation) {
            const defaultGridSettingsLocationColumn = defaultGridSettings.columnsConfig.find(x => x.field === "locationName");
            if (defaultGridSettingsLocationColumn) {
                defaultGridSettingsLocationColumn.hidden = true;
            }

            const gridSettingsLocationColumn = gridSettings.columnsConfig.find(x => x.field === "locationName");
            if (gridSettingsLocationColumn) {
                gridSettingsLocationColumn.hidden = true;
            }
        }
    }

    uniqueLocationsCount(items: any): number {
        const locations = items?.map(x => x.locationName);
        const uniqueLocations = new Set(locations);
        return uniqueLocations?.size;
    }

    isNullUndefinedOrEmpty(value) {
        return _isNil(value) || _isEmpty(value);
    }
}
